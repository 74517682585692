import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="March 2023" />
		<NewsHeader />
		<h2>March 2023</h2>

		<h3 className="blogdate">
			Thursday, March 2, 2023
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">Close to Me: new video/single</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				My partner Jane and I executed a quick and simple little video for a new instrumental <span className="italic">feeling</span> of mine.
				We hope it leaves you feeling warm and reflective.
				Find it and read more about it at my <Link to="/music/14/">music page</Link>.
			</p>

			<div className="main--centerwrapper p">
				<StaticImage
					src="../../images/catalog artwork/JRIVEST14 close front.jpg"
					alt="Jesse Rivest - Close to Me - cover art"
					placeholder="blurred"
					layout="constrained"
					className="main--centered"
					imgClassName="img--bordered-small"
					width={300}
				/>
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
